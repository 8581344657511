import React, { useEffect } from "react";
import Layout from '../../components/layout/Layout'
import { useStore } from "../../context/StoreContext";

const MerciPageAtelier = () => {

  const {createCart} = useStore()

  // Reset cart and checkout when coming to this URL (checkout finished)
  useEffect(() => {
    createCart()
  })

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Merci pour votre réservation !</h1>
            <p>A bientot sur place ... ;)</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default MerciPageAtelier;
